// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
// Brand themes
.brand-dark {
    @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
    @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";
// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.example-highlight>pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;
        span {
            background: none transparent !important;
        }
    }
}

.json>pre {
    background: none #fff !important;
}

.example-code>.json {
    background: none #fff !important;
}

.symbol.symbol-45>svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;
        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }
            &:hover {
                cursor: pointer;
                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }
    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.table-not-responsive table {
    width: auto;
    min-width: unset !important;
}

.table-striped-rows tr:nth-child(2n) td {
    background-color: #fbfbfb;
}

.table-striped-rows thead {
    background-color: #f1f1f1;
}


/* tile uploaded pictures */

.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
}

.upload-list-inline [class*="-upload-list-rtl"] .ant-upload-list-item {
    float: right;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    min-height: 32px;
    cursor: pointer;
}

.editable-row .editable-cell-value-wrap:hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme="dark"] .editable-row .editable-cell-value-wrap:hover {
    border: 1px solid #434343;
}

.custom-timeline .timeline.timeline-5 .timeline-item .timeline-label {
    width: 8rem;
    margin-right: 1rem;
}

.custom-timeline .timeline.timeline-5:before {
    left: 9rem;
}

.custom-timeline .timeline.timeline-5 .timeline-item .timeline-badge {
    border: 0.2em solid #87ceeb !important;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-shadow: 0 1px 0 #424242;
    margin-left: -1.3rem;
}

.steps-content {
    margin-top: 16px;
}

.steps-action {
    margin-top: 24px;
}

#react-org-chart {
    margin: 0;
    cursor: move;
    min-height: 60vh;
    width: 100%;
    background-color: #f7f9fa;
}