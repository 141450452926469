.custom-timeline .timeline.timeline-5:before {
  left: 17.8rem;
}

.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
  font-weight: bolder;
}

.pl-5-custom {
  padding-left: 15.5rem !important;
}

.anticon-custom {
  color: #fff;
  line-height: 2;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #77d85a;
  border-color: #ddc5c6;
  color: #ffffff;
}

.carousel-container {
  position: relative;
  height: 300px;
  max-width: 960px;
  margin: 0 auto;
  overflow: hidden;
  touch-action: pan-y;
}

.carousel-track {
  display: flex;
  height: 100%;
}

.carousel-card {
  flex: 0 0 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

.carousel-card-inner {
  position: relative;
  flex-direction: column;
  width: 290px;
  height: 290px;
  border-radius: 5px;
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}
.sunumSayfaModal1 {
  min-height: 500px;
  min-width: 80%;
  background: url("/media/presentation/bg1.png") no-repeat center/88%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sunumSayfaModal2 {
  min-height: 600px;
  min-width: 80%;
  background: rgb(232, 230, 226);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sunumSayfaModalSagBar {
  min-height: 600px;
  background: rgb(87, 91, 66) no-repeat center/100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sunumSayfaModal3 {
  min-height: 500px;
  min-width: 80%;
  background: #d7d7e1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sunumSayfaModal4 {
  min-height: 500px;
  min-width: 80%;
  background: #9ba371;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sunumSayfaModal5 {
  min-height: 500px;
  min-width: 80%;
  background: rgb(232, 230, 226);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.headerText {
  margin-top: 50px;
  margin-left: 30px;
  font-size: 2em;
  z-index: 9999;
  background: transparent;
  border-bottom: #1a1a27 2px;
  font-family: "Public Sans";
}

.headerTextRight {
  margin-top: 50px;
  margin-right: 30px;
  font-size: 2em;
  z-index: 9999;
  background: transparent;
  border-bottom: #1a1a27 2px;
  font-family: "Public Sans";
}

/* PickListDemo.css */

.picklist-demo .product-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.picklist-demo .product-item img {
  width: 75px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 1rem;
}

.picklist-demo .product-item .product-list-detail {
  flex: 1 1 0;
}

.picklist-demo .product-item .product-list-action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.picklist-demo .product-item .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.picklist-demo .product-item .product-category {
  vertical-align: middle;
  line-height: 1;
}

@media screen and (max-width: 576px) {
  .picklist-demo .product-item {
    flex-wrap: wrap;
  }

  .picklist-demo .product-item .image-container {
    width: 100%;
    text-align: center;
  }

  .picklist-demo .product-item img {
    margin: 0 0 1rem 0;
    width: 100px;
  }
}

.site-input-group-wrapper .site-input-split {
  background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
  border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}

.react-flow .react-flow__handle {
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: #784be8;
}

.react-flow .react-flow__handle-top {
  top: -7px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -7px;
}

.react-flow .react-flow__node {
  height: auto;
  width: auto;
  max-width: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-width: 2px;
  font-weight: 700;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}
.updatenode__controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 4;
  font-size: 12px;
}

.updatenode__controls label {
  display: block;
}

.updatenode__bglabel {
  margin-top: 10px;
}

.updatenode__checkboxwrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.updatenode__controls button {
  margin-left: 5px;
}

.ant-card-head {
  color: rgba(178, 136, 12, 0.85);
  font-weight: 500;
  font-size: 2em;
}

.modal-90w {
  width: 90%;

}

.ant-ribbon {
  font-size: 20px;
  top: 8px;
  height: 28px;
  padding: 2px 8px;
}