button.custom-assignment-button {
  background-color: #f2f2f2 !important; /* Özel stil */
  color: var(--tw-gray-700) !important;
  border: none !important; /* Varsayılan kenarlıkları kaldır */
  border-radius: 6px !important;
}

.custom-assignment-button:hover {
  background-color: #e6e6e6; /* Hover için daha koyu gri */
  color: #333; /* Yazı rengi hover sırasında daha koyu siyah ton */
}
