:root {
  --chatWidthDesktop: 65%;
  --chatWidthMobile: 90%;
}


.gpt-chat-container {
  min-height: calc(98vh - var(--headerHeight));
  max-height: calc(98vh - var(--headerHeight));
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
}

.gpt-chat-container .chat {
  flex: 1;
  max-height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  font-size: 17px;
}

.gpt-chat-container .chat .message {
  color: black;
  padding: 8px;
  width: var(--chatWidthDesktop);
  max-width: var(--chatWidthDesktop);
  display: flex;
  gap: 5px;
}

.gpt-chat-container .chat .message.me .ant-avatar {
  background-color: rgb(131, 215, 174);
}

.gpt-chat-container .chat .message.him .ant-avatar {
  background-color: rgb(0, 21, 41) !important;
}

.gpt-chat-container .chat .message.him.animate-init {
  animation: init 0.2s ease-in-out;
}

.gpt-chat-container .chat .message .avatar {
  width: 32px;
  height: 32px;
}

.gpt-chat-container .chat .message .message-content {
  flex: 1;
  max-width: 90%;
}

.chat .message.him .message-content {
  padding-bottom: 15px;
}


.gpt-chat-container .chat .message .message-content .head {
  font-weight: 600;
  height: 32px;
  display: flex;
  align-items: center;
}

.gpt-chat-container .chat>.blank-chat {
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.gpt-chat-container .chat>.blank-chat>.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.gpt-chat-container .chat>.blank-chat>.content .gpt-icon {
  width: 50px !important;
  height: 50px !important;
}

.gpt-chat-container .chat>.blank-chat>.content .text {
  font-weight: 500;
  font-size: 20px;
}

.gpt-chat-container .chat>.blank-chat.show {
  display: flex;
}

.gpt-chat-container .input-container {
  width: var(--chatWidthDesktop);
  min-height: min-content;
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 0 auto;
  gap: 10px;
}

.gpt-chat-container .input-container .area-container {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.gpt-chat-container .input-container button {
  flex-grow: 0;
}

.gpt-chat-container .chat .edit-container {
  opacity: 0;
}

.gpt-chat-container .chat .message:hover .edit-container {
  opacity: 1;
}

.gpt-menu-col {
  background-color: rgb(235, 235, 235);
  max-height: 100%;
  height: calc(100vh - var(--headerHeight)) !important;
  display: flex;
  flex-direction: column;
}


.gpt-menu-col>.scroll {
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.gpt-menu-col ul {
  background-color: rgb(235, 235, 235);
}

.gpt-chat-col {
  background-color: rgb(235, 235, 235);
}

.gpt-container2 {
  max-height: calc(100vh - var(--headerHeight));
  background-color: rgb(235, 235, 235);
}

.gpt-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 21, 41);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

@keyframes init {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0px);
  }
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#gpt-menu li {
  height: 36px !important;


}


.gpt-container2 .head1 {
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgb(235, 235, 235);
  ;
  z-index: 10;
}


.gpt-container2 .header1 {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 8px;
}

.mobile-top-bar {
  display: none;

}

.close-mobile-menu {
  display: none;
}

@media screen and (max-width: 992px) {

  .gpt-chat-container .chat .message {
    width: var(--chatWidthMobile);
    max-width: var(--chatWidthMobile);
  }

  .gpt-chat-container .input-container {
    width: var(--chatWidthMobile);
  }


  .gpt-menu-col.open {
    width: 220px !important;

  }

  .gpt-container2.ant-row {
    flex-flow: column;
  }

  .gpt-menu-col {
    transition: all 0.3s ease-in-out;
    top: 0;
    position: absolute !important;
    width: 200px;
    z-index: 20;
    left: -105%;
    background-color: rgb(235, 235, 235);


    -webkit-box-shadow: 10px 6px 11px -6px rgba(199, 199, 199, 1);
    -moz-box-shadow: 10px 6px 11px -6px rgba(199, 199, 199, 1);
    box-shadow: 10px 6px 11px -6px rgba(199, 199, 199, 1);
  }

  .gpt-menu-col.open {
    left: 15px;
    height: calc(100vh - 130px) !important;
    top: 62px !important;
  }

  .gpt-chat-container .chat {
    padding-top: 65px;
  }

  .gpt-container2 .new-chat-container {
    margin-top: 20px
  }

  .gpt-container2 .header1 {
    display: none;
  }


  .mobile-top-bar {
    position: fixed;
    left: 0;
    top: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    background-color: rgb(235, 235, 235);
    align-items: center;
    width: 100%;



    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }

  .mobile-top-bar>.r.r1 {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    cursor: pointer;
  }

  .mobile-top-bar>.r.r2 {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .close-mobile-menu {
    display: fixed;
    position: absolute;
    left: 220px;
    top: 10px;
    cursor: pointer;
    z-index: 20;
  }
}



#gpt-page {
  margin-top: -55px;
  margin-left: -4px;
  z-index: 95;
  margin-left: -34px;
  margin-right: -13px;
  position: relative;
  max-height: calc(98vh - var(--headerHeight)) !important;
}

#gpt-menu .menu-container {
  width: 100%;
  position: relative;
}

#gpt-menu li.ant-menu-item {
  padding-right: 2px !important;
}

#gpt-menu .menu-container>.more {
  position: absolute;
  width: 30px;
  height: 30px;
  display: none;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);
  transform-origin: center;
  font-size: 40px;
  background-color: rgb(235, 235, 235);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  /* box-shadow: -39px 0px 20px -20px rgba(0,0,0,0.45); */
}

#gpt-menu .menu-container:hover>.more {
  display: flex;
}

#gpt-menu .menu-container>.more:hover {
  background-color: rgb(92, 93, 93);
  color: white;
}

.gpt-menu-col.open .more {
  display: flex !important;
}