.item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 110px;
  height: 30px;
  margin-bottom: 5px;
  padding-left: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  user-select: none;
  background-color: white;
}
