/* "Yeni Görev Ekle" düğmesini gizle */
.gantt_button_add {
  display: none !important;
}

/* "+" ikonunu gizle */
.gantt_add,
.gantt_grid_head .gantt_add,
.gantt_cal_larea .gantt_add_button {
  display: none !important;
}

.gantt_grid_data .gantt_last_cell,
.gantt_grid_scale .gantt_last_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell.gantt_last_cell,
.gantt_task_bg .gantt_last_cell {
  display: none !important;
}